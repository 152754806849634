import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  CustomVendorContainer,
  ViewStatusBlock,
} from "../../Utils/VendorStyledTags";
import CircleIcon from "@mui/icons-material/Circle";
import { useVendor } from "../../../../contexts/VendorContext";
import TopNavBarWebView from "../../../../layouts/TopNavBarWebView";

export const ServiceOpeningHours = () => {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );

  const isBoth = isLargeScreen || isMediumScreen;
  const navigate = useNavigate();
  const { availableTime } = useVendor();

  const orderedDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const timeFormat = JSON.parse(localStorage.getItem("user"))["timeFormat"];
  const handleClickBackIcon = () => {
    navigate("/vendor/vendorService");
  };

  const alteredDay = {
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday",
  };

  function convertTo12HourFormat(timeRange) {
    const [startTime, endTime] = timeRange.split("-");
    const formattedStartTime = convertTo12Hour(startTime);
    const formattedEndTime = convertTo12Hour(endTime);
    const output = `${formattedStartTime} - ${formattedEndTime}`;

    return output;
  }

  function convertTo12Hour(time) {
    const [hours, minutes] = time.split(":");
    let hoursNum = parseInt(hours, 10);
    const period = hoursNum >= 12 ? "pm" : "am";
    if (hoursNum === 0) {
      hoursNum = 12;
    } else if (hoursNum > 12) {
      hoursNum -= 12; 
    }
    const formattedTime = `${padZero(hoursNum)}:${minutes} ${period}`;
    return formattedTime;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : `${num}`;
  }
  const handleClickUpdateTime = () => {
    navigate("/vendor/vendorUpdatingTime", {
      state: availableTime,
    });
  };

  const newTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentTimeZone = JSON.parse(localStorage.getItem("user"))["timeZone"];

  // Get the time in different time zones using Intl.DateTimeFormat
  const timeZone1Offset = new Date().toLocaleString("en-US", {
    timeZone: newTimeZone,
  });
  const timeZone2Offset = new Date().toLocaleString("en-US", {
    timeZone: currentTimeZone,
  });

  // Convert them to UTC Date objects
  const dateInTimeZone1 = new Date(timeZone1Offset);
  const dateInTimeZone2 = new Date(timeZone2Offset);

  // Get the difference in milliseconds
  const diffInMilliseconds = dateInTimeZone2 - dateInTimeZone1;

  // Convert to hours
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

  const timeZoneDifferenceMessage =
    diffInHours > 0
      ? `The displayed opening hours are currently behind by ${Math.abs(
          diffInHours
        )} hours from your current time zone ${newTimeZone}.`
      : `The displayed opening hours are currently ahead by ${Math.abs(
          diffInHours
        )} hours from your current time zone ${newTimeZone}.`;

  return (
    <>
      <main>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            padding: "0px",
            background: "var(--gray-50, #F9FAFB)",
          }}
        >
          {isBoth && <TopNavBarWebView index={3} />}

          <CustomVendorContainer>
            <Container
              maxWidth="sm"
              style={{
                zIndex: 10,
                padding: isBoth ? "10px" : "0px",
                margin: isLargeScreen
                  ? `40px calc((100% - 1060px) / 2) 40px calc(((100% - 1060px) / 2) + 400px)`
                  : "0px auto",
                border: isBoth ? "1px solid var(--gray-200, #E5E7EB)" : "none",
                background: "#FFF",
                borderRadius: "20px",
                display: "flex",
                flexDirection: "column",
                minHeight: isBoth ? "auto" : "100vh",
                position: "relative",
                paddingBottom: "82px",
              }}
            >
              <ViewStatusBlock
                style={{
                  justifyContent: isBoth ? "space-between" : "center",
                  alignItems: "center",
                  borderBottom: isBoth
                    ? "1px solid var(--gray-200, #E5E7EB)"
                    : "none",
                  paddingBottom: isBoth ? "16px" : "0px",
                  position: "relative",
                }}
              >
                {!isBoth && (
                  <ArrowBackIosIcon
                    onClick={handleClickBackIcon}
                    sx={{ position: "absolute", top: isBoth ? 5 : 0, left: 0 }}
                  />
                )}

                <Typography
                  style={{
                    fontWeight: isBoth ? 600 : 500,
                    color: "#1C1C1C",
                    fontFamily: "Poppins",
                    fontSize: isBoth ? "28px" : "18px",
                    margin: isBoth ? " 0px 30px" : "0px",
                    lineHeight: "130%",
                    textTransform: "capitalize",
                  }}
                >
                  Opening Hours
                </Typography>
              </ViewStatusBlock>{" "}
              <Container
                maxWidth="sm"
                sx={{ flexGrow: 1, overflowY: "auto", padding: "16px" }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--gray-600, #4B5563)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    textAlign: "left",
                  }}
                >
                  Enhance and streamline the management of your store's daily
                  activities effortlessly.
                </Typography>
                {diffInHours !== 0 && (
                  <Typography
                    sx={{
                      color: "red",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      textAlign: "left",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    {timeZoneDifferenceMessage} Click 'Edit' to reset your
                    opening hours based on your current time zone.
                  </Typography>
                )}

                <TableContainer
                  display="flex"
                  justifyContent="center"
                  align-items="center"
                  flex-shrink="0"
                  style={{
                    borderRadius: "16px",
                    marginTop: "20px",
                    marginBottom: "50px",
                  }}
                >
                  <Table ariaLabel="simple table">
                    {Object.keys(availableTime).length > 0 && (
                      <TableBody>
                        {orderedDays.map((day) => (
                          <TableRow
                            key={day}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              style={{ padding: "0px" }}
                            >
                              <Typography
                                style={{
                                  color: "var(--gray-900, #111827)",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "500",
                                  lineHeight: "18px",
                                  textTransform: "capitalize",
                                }}
                              >
                                {alteredDay[day]}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: "16px 0px" }}>
                              {availableTime[day] &&
                                availableTime[day].slice(9, 17).toString() !==
                                  "23:59:59" &&
                                availableTime[day] !== null && (
                                  <Button
                                    align="right"
                                    style={{
                                      padding: "1px 12px 1px 8px",
                                      alignItems: "center",
                                      justifyContent: "right",
                                      borderRadius: "12px",
                                      color: "#065F46",
                                      background: "#D1FAE5",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                    }}
                                  >
                                    <CircleIcon
                                      sx={{
                                        color: "#34D399",
                                        fontSize: "8px",
                                        marginRight: "6px",
                                      }}
                                    />
                                    Open
                                  </Button>
                                )}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                color: "var(--gray-500, #6B7280)",
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "20px",
                                padding: "16px 0px",
                              }}
                            >
                              {availableTime[day] ? (
                                availableTime[day] !== null ? (
                                  availableTime[day].slice(9, 17) ===
                                  "23:59:59" ? (
                                    <Button
                                      align="right"
                                      style={{
                                        padding: "1px 12px 1px 8px",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        borderRadius: "12px",
                                        color: "#065F46",
                                        background: "#D1FAE5",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <CircleIcon
                                        sx={{
                                          color: "#34D399",
                                          fontSize: "8px",
                                          marginRight: "6px",
                                        }}
                                      />
                                      Open 24 hours
                                    </Button>
                                  ) : (
                                    <span>
                                      {timeFormat === "_24HOURS"
                                        ? availableTime[day]
                                            .split("-")
                                            .map((time) => time.slice(0, 5))
                                            .join(" - ")
                                        : convertTo12HourFormat(
                                            availableTime[day]
                                          )}
                                    </span>
                                  )
                                ) : (
                                  <span>
                                    {convertTo12HourFormat(availableTime[day])}
                                  </span>
                                )
                              ) : (
                                <Button
                                  align="right"
                                  style={{
                                    padding: "1px 12px 1px 8px",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    borderRadius: "12px",
                                    color: "#991B1B",
                                    backgroundColor: "#FEE2E2",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                  }}
                                >
                                  <CircleIcon
                                    sx={{
                                      color: "#F87171",
                                      fontSize: "8px",
                                      marginRight: "6px",
                                    }}
                                  />
                                  Closed
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "50px",
                    left: "16px",
                    right: "16px",
                    marginTop: isBoth ? "50px" : "0px",
                  }}
                >
                  <Button
                    onClick={handleClickUpdateTime}
                    sx={{
                      background: "var(--gray-900, #111827)",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "16px 13px",
                      borderRadius: "8px",
                      width: "100%",
                      "&:hover": {
                        background: "var(--gray-900, #111827)",
                      },
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--white, var(--white, #FFF))",
                        fontFamily: "Poppins",
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "capitalize",
                      }}
                    >
                      Edit opening hours
                    </Typography>
                  </Button>
                </Box>
              </Container>
              {/* {!isBoth && <VendorBottomNavbar clickedIndex={2} />}{" "} */}
            </Container>
          </CustomVendorContainer>
        </Box>
      </main>
    </>
  );
};
